import React from "react"
import ImageMeta from "../components/ImageMeta"

import Layout from "../components/layout"
import SEO from "../components/seo"

import tree from "../images/404tree.svg"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"

import { Link } from 'gatsby';

const NotFoundPage = () => (
  
    <Layout language="en">
      <SEO
        title="404: Page Not Found"
        description="Oooops, it looks like the page you’re trying to reach is missing or no longer available. Please check the URL you typed for spelling and capitalization. If you’re still having problems, please visit the hompeage."
        robots="noindex"
      />

    </Layout>
  
)

export default NotFoundPage
